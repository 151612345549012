<template>
    <div class="building">
        <img
            src="../assets/building.jpg"
            alt=""
        >
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {};
    },
    computed: {},
    created() { },
    mounted() { },
    methods: {},
};
</script>

<style lang="scss" scoped>
.building {
  display: flex;

  img {
    width: 100vw;
  }
}
</style>
